html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'NouvelRRegular';
  src: local(NouvelRRegular),
  url('/public/fonts/NouvelR-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NouvelRBold';
  src: local(NouvelRBold),
  url('/public/fonts/NouvelR-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  color: #000;
  font-family: 'NouvelRRegular', sans-serif;
  font-size: 14px;
}

#root {
  overflow: hidden;
}

main {
  display: flex;
}

table {
  border-bottom: 2px solid #ccc;
  border-collapse: separate;
}

table.sticky.sticky--left {
  position: sticky;
  left: 0;
}

thead.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

th, td {
  padding: 10px;
  font-size: 14px;
}

th {
  font-family: 'NouvelRBold';
  vertical-align: middle;
}

th.normal-border {
  border: 1px solid rgba(0,0,0,.1);
}

th.sticky {
  position: sticky;
  left: 0;
}

th.nowrap {
  white-space: nowrap;
}

td {
  white-space: nowrap;
}

td.sticky {
  position: sticky;
  left: 0;
}

th.sticky.sticky--left.sticky--top {
  position: sticky;
  left: 0;
  top: 0;
}

tr:nth-child(odd) td {
  background-color: #fff;
}

tr:nth-child(odd) td.bg--gray {
  background-color: #f6f6f6;
}

tr:nth-child(even) td {
  background-color: #f2f2f2;
}

tr:nth-child(even) td.bg-white {
  background-color: #fff;
}

tr:nth-child(even) td.bg--gray {
  background-color: #f6f6f6;
}

b, strong {
  font-family: NouvelRBold;
}

input, label, label span, p {
  font-family: 'NouvelRRegular' !important;
}

.page-wrapper {
  position: relative;
  padding: 0 20px 0 0;
  width: 100%;
  height: calc(100vh);
}

.f-bold {
  font-family: NouvelRBold;
}

.f-white {
  color: #fff;
}

.f-grey {
  color: #f6f6f6;
}

.f-transparent {
  color: transparent;
}

.f-to-right {
  text-align: right;
}

.f-plus {
  color: #19a427;
}

.f-minus {
  color: #e91630;
}

.f-sum {
    background-color: #fdfd96 !important;
}

.text-center {
  text-align: center;
}

.t-relative {
  position: relative;
}

.t-absolute {
  position: absolute;
}

.t-top-border {
  border-top: 2px solid #ccc;
}

.t-top-border--strong td {
  border-top: 1px solid #c5c5c5;
}

.t-right-border {
  border-right: 2px solid #ccc;
}

.t-left-border {
  border-left: 2px solid #ccc;
}

.t-bottom-border {
  border-bottom: 2px solid #ccc;
  padding-bottom: 8px;
}

.bg--gray {
  background-color: #f6f6f6;
}

.bg--odd {
  background-color: #fff;
}

.bg--even {
  background-color: #f2f2f2;
}

.nowrap {
  white-space: nowrap;
}

.bg-renault {
  background-color: #efdf00;
}

.bg-dacia {
  background-color: #646b52;
  color: #fff;
}

.bg-visits {
  background-color: #f8dcaa;
}

.bg-turnover {
  background-color: #b2f1cc;
}

.bg-basket {
  background-color: #c6ebfd;
}

.bg-white {
  background-color: #fff;
}

.bg-default {
  background-color: #d9d9d6;
}

.h28 {
  height: 28px;
}

.z2 {
  position: relative;
  z-index: 2;
}

.MuiFormControl-root {
  border: 2px solid black;
}

.MuiInputLabel-root {
  color: #e91630;
}

.MuiButtonBase-root.MuiTab-root {
  font-family: 'NouvelRRegular';
  color: #000;
  font-size: 16px;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  font-family: 'NouvelRBold';
  color: #000;
}

.MuiTabs-indicator {
  background-color: #000 !important;
}

.page-wrapper > div > .MuiBox-root {
  width: calc(100vw - 340px);
  margin-left: 320px;
  transition: margin-left .2s ease-in-out, width .2s ease-in-out;
}

.hideSideBar .page-wrapper > div > .MuiBox-root {
  margin-left: 20px;
  width: calc(100vw - 40px);
}

.page-wrapper > div > .MuiBox-root > div > .MuiBox-root {
  padding: 24px 0 !important;
}

.rc-slider {
  position: relative;
  top: 10px;
  width: 250px !important;
}

.rc-slider-rail {
  background-color: #d9d9d6 !important;
}

.rc-slider-track {
  background-color: #efdf00 !important;
}

.rc-slider-handle, .rc-slider-handle-dragging {
  width: 20px !important;
  height: 20px !important;
  margin-top: -8px !important;
  border: none !important;
  background-color: #efdf00 !important;
  opacity: 1 !important;
  box-shadow: none !important;
}

.rc-slider-dot {
  bottom: -4px !important;
  width: 12px !important;
  height: 12px !important;
  border: none !important;
  background-color: #d9d9d6 !important;
}

.rc-slider-dot-active {
  background-color: #efdf00 !important;
}

.rc-slider-mark {
  top: -24px !important;
}
.rc-slider-mark-text {
  font-family: 'NouvelRRegular';
  color: #000 !important;
  font-size: 14px;
  white-space: nowrap;
}

.rc-slider-mark-text-active {
   font-family: 'NouvelRBold';
}
